import { useMemo } from "react";
import { addMinutes } from "date-fns";
import parseISO from "date-fns/parseISO";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import {
  MarkAppointmentOfferingAsReadingParams,
  MarkAppointmentOfferingAsReadingResponse,
} from "@Shape-Digital/kudzu-data/lib/types/actions";
import { AppointmentOfferingRowItem } from "../AppointmentOfferingTableGrid/types";
import useTranslations from "./useTranslations";
import { useUIContext } from "../../Unknown/UIContext";
import {
  getTimezoneOffsetMillis,
  UTCDateTimeToTimezone,
} from "../../../common/dateHelpers";
import { DashboardAppointmentOffering } from "../AppointmentOfferingTableContainer/getAppointmentOfferings";
import useSystemConfig from "../../../hooks/useSystemConfig";
import getReportDeliveryDeadlineHours from "./getReportDeliveryDeadlineHours";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

const normalizeOffering = ({
  appointmentOffering,
  systemConfig,
  markAppointmentOfferingAsReading,
}: {
  appointmentOffering: DashboardAppointmentOffering;
  systemConfig: DatabaseRow<"system_configs">;
  markAppointmentOfferingAsReading: (
    params: MarkAppointmentOfferingAsReadingParams,
  ) => MarkAppointmentOfferingAsReadingResponse;
}): AppointmentOfferingRowItem => {
  const { name, region, timezone } = appointmentOffering.appointment.center;

  const reportDeliveryDeadlineHours = getReportDeliveryDeadlineHours({
    appointmentOffering,
    systemConfig,
  });

  const appointmentOfferingPatientDetails =
    appointmentOffering.appointment.appointment_patient_details[0];

  const { first_name, last_name, date_birth, mobile_phone_number, patient } =
    appointmentOfferingPatientDetails;

  const { mrn } = patient;

  const startedAtUTC = parseISO(
    appointmentOffering.appointment.time_slot.started_at,
  );

  const appointmentDurationMinutes =
    appointmentOffering.appointment.appointment_offerings.reduce(
      (totalDuration, { center_offering }) => {
        return totalDuration + (center_offering.duration_minutes || 0);
      },
      0,
    );

  const readyToReadAtUTC = addMinutes(
    new Date(appointmentOffering.appointment.time_slot.started_at),
    appointmentDurationMinutes,
  );

  const startedAtCenterTimezone = UTCDateTimeToTimezone(
    startedAtUTC,
    getTimezoneOffsetMillis(timezone),
  );

  const readyToReadAtCenterTimezone = readyToReadAtUTC
    ? UTCDateTimeToTimezone(readyToReadAtUTC, getTimezoneOffsetMillis(timezone))
    : null;

  const onReadClick = async () => {
    if (!appointmentOffering.pacs_url) return;

    window.open(appointmentOffering.pacs_url);

    await markAppointmentOfferingAsReading({
      appointmentOfferingId: appointmentOffering.id,
    });
  };

  const onEditClick = async () => {
    if (!appointmentOffering.pacs_url) return;

    window.open(appointmentOffering.pacs_url);
  };

  const [technologistNotes] =
    appointmentOffering?.appointment?.appointment_technologist_notes || [];

  const { exam_reason: examReason } =
    (appointmentOffering?.appointment?.appointment_patient_details_form ||
      [])[0] || {};

  const formattedExamReason = examReason ? `"${examReason}"` : null;

  const notes =
    [formattedExamReason, technologistNotes?.text].filter(Boolean).join(" ") ||
    "";

  const offering: AppointmentOfferingRowItem = {
    id: appointmentOffering.id,
    status: appointmentOffering.status,
    appointmentStatus: appointmentOffering.appointment.status,
    name: appointmentOffering.center_offering.name,
    readyToReadAtCenterTimezone,
    reportDeliveryDeadlineHours,
    side: appointmentOffering.side || undefined,
    appointmentPatient: {
      firstName: first_name,
      lastName: last_name,
      dateOfBirth: parseISO(date_birth),
      phoneNumber: mobile_phone_number || "",
    },
    isAdditionalViews: !!appointmentOffering.appointment.is_additional_views,
    isStat: !!appointmentOffering.appointment.is_stat,
    hasPriority: !!appointmentOffering.appointment.has_priority,
    hasUnexpectedFindings:
      !appointmentOffering.is_receipt_confirmed &&
      !!appointmentOffering.has_unexpected_findings,
    isUnexpectedFindingsReceiptConfirmed:
      !!appointmentOffering.is_receipt_confirmed,
    patient: {
      mrn,
    },
    startedAtUTC,
    startedAtCenterTimezone,
    center: {
      name,
      locale: region,
      timezone,
    },
    appointment: {
      id: appointmentOffering.appointment.id,
    },
    notes,
    onReadClick,
    onEditClick,
  };

  return offering;
};

const useRows = (appointmentOfferings: DashboardAppointmentOffering[]) => {
  const { defaultError } = useTranslations();
  const { setAlert } = useUIContext();
  const {
    dataState: { data: systemConfig },
  } = useSystemConfig();
  const markAppointmentOfferingAsReading = useFirebaseAppFunction(
    "markAppointmentOfferingAsReading",
  );

  const data = useMemo<AppointmentOfferingRowItem[]>(() => {
    try {
      if (!systemConfig) return [];
      const rows = appointmentOfferings.map((appointmentOffering) =>
        normalizeOffering({
          appointmentOffering,
          systemConfig,
          markAppointmentOfferingAsReading,
        }),
      );
      return rows;
    } catch (error) {
      setAlert({ isShown: true, severity: "error", message: defaultError });
    }

    return [];
  }, [
    appointmentOfferings,
    defaultError,
    markAppointmentOfferingAsReading,
    setAlert,
    systemConfig,
  ]);

  return data;
};

export default useRows;
