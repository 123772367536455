import Big from "big.js";
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset";
import addMinutes from "date-fns/addMinutes";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import hoursToMinutes from "date-fns/hoursToMinutes";
import startOfDay from "date-fns/startOfDay";
import { SettingsCenter } from "./getSettingsCenterData";
import { OperatingHours, OperatingHoursDayTime } from "./types";

const getMinutesDateCenterTZ = (minutes: number) => {
  const dateTime = addMinutes(startOfDay(new Date()), minutes);
  return dateTime;
};

const getMinutesFromDate = (date: Date) => {
  const minutes = getMinutes(date);
  const hours = getHours(date);

  return Big(hoursToMinutes(hours)).plus(minutes).toNumber();
};

const normalizeOperatingHours = (center: SettingsCenter): OperatingHours => {
  const { center_operating_hours, timezone } = center;

  const centerTimezoneOffsetMillis = timezone ? getTimezoneOffset(timezone) : 0;

  const weekOperatingHours = center_operating_hours.reduce<
    OperatingHours["weekOperatingHours"]
  >((acc, opHours) => {
    const { from_minutes_at, to_minutes_at } = opHours;

    const fromMinutesAtCenterTZDate = getMinutesDateCenterTZ(from_minutes_at);
    const fromMinutesAtCenterTZ = getMinutesFromDate(fromMinutesAtCenterTZDate);

    const toMinutesAtCenterTZDate = getMinutesDateCenterTZ(to_minutes_at);
    const toMinutesAtCenterTZ = getMinutesFromDate(toMinutesAtCenterTZDate);

    const dayOpHours: OperatingHoursDayTime = {
      fromMinutesAtCenterTZDate,
      toMinutesAtCenterTZDate,
      fromMinutesAtCenterTZ,
      toMinutesAtCenterTZ,
    };

    return { ...acc, [opHours.day_of_week_number]: dayOpHours };
  }, {});

  return {
    weekOperatingHours,
    centerTimezoneOffsetMillis,
  };
};

export default normalizeOperatingHours;
