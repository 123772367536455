import { Theme, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ palette, spacing }) => {
  return {
    labelRoot: {
      fontSize: 12,
      letterSpacing: 1,
      textTransform: "uppercase",
      color: alpha(palette.common.black, 0.6),
    },
    btnAdd: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
  };
});

export default useStyles;
