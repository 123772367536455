import { defineMessages, useIntl } from "react-intl";

import commonMessages from "../../../common/messages";

const messages = defineMessages({
  title: {
    id: "Appointment.AppointmentModifyServicesDialog.title",
    defaultMessage: "Edit Offerings",
  },
  btnSubmit: {
    id: "Appointment.AppointmentModifyServicesDialog.btnSubmit",
    defaultMessage: "Confirm Changes",
  },
  btnCancel: {
    id: "Appointment.AppointmentModifyServicesDialog.btnCancel",
    defaultMessage: "Cancel",
  },
  errorMessage: {
    id: "Appointment.AppointmentModifyServicesDialog.errorMessage",
    defaultMessage: "An error occurred while saving the changes.",
  },
  addInputButton: {
    id: "Appointment.AppointmentModifyServicesDialog.addInputButton",
    defaultMessage: "Add another MRI scan with {discountAmount} discount",
  },
  singleOfferingsTitle: {
    id: "Appointment.AppointmentModifyServicesDialog.singleOfferingsTitle",
    defaultMessage: "Single Offerings",
  },
  packagesTitle: {
    id: "Appointment.AppointmentModifyServicesDialog.packagesTitle",
    defaultMessage: "Packages",
  },
  selectScan: {
    id: "Appointment.AppointmentModifyServicesDialog.selectScan",
    defaultMessage: "Select MRI Scan",
  },
  selectSideLabel: {
    id: "Appointment.AppointmentModifyServicesDialog.selectSideLabel",
    defaultMessage: "Select side",
  },
  leftSide: {
    id: "Appointment.AppointmentModifyServicesDialog.leftSide",
    defaultMessage: "Left",
  },
  rightSide: {
    id: "Appointment.AppointmentModifyServicesDialog.rightSide",
    defaultMessage: "Right",
  },
  selectAdOnsTitle: {
    id: "Appointment.AppointmentModifyServicesDialog.selectAdOnsTitle",
    defaultMessage: "Add some extras",
  },
});

const useTranslations = () => {
  const { formatMessage, formatNumber } = useIntl();

  const translations = {
    title: formatMessage(messages.title),
    btnSubmit: formatMessage(messages.btnSubmit),
    btnCancel: formatMessage(messages.btnCancel),
    errorMessage: formatMessage(messages.errorMessage),
    edit: formatMessage(commonMessages.edit),
    singleOfferingsTitle: formatMessage(messages.singleOfferingsTitle),
    packagesTitle: formatMessage(messages.packagesTitle),
    removeBtn: formatMessage(commonMessages.remove),
    selectScan: formatMessage(messages.selectScan),
    selectSideLabel: formatMessage(messages.selectSideLabel),
    leftSide: formatMessage(messages.leftSide),
    rightSide: formatMessage(messages.rightSide),
    selectAdOnsTitle: formatMessage(messages.selectAdOnsTitle),
    addInputButton: ({
      discountAmount,
      defaultCurrencyCode,
    }: {
      discountAmount: number;
      defaultCurrencyCode: string;
    }) => {
      const discountAmountString = formatNumber(discountAmount || 0, {
        style: "currency",
        currency: defaultCurrencyCode,
      }).replace(".00", "");

      return formatMessage(messages.addInputButton, {
        discountAmount: discountAmountString,
      });
    },
  };

  return { translations };
};
export default useTranslations;
