import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import set from "date-fns/set";
import { useCallback, useState } from "react";
import { convertZonedDateToUtcDateString } from "../../../common/dateHelpers";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { AppointmentRescheduleDialogProps } from "../AppointmentRescheduleDialog";
import { Appointment, Center } from "./types";
import useTranslations from "./useTranslations";

type Result = Pick<
  AppointmentRescheduleDialogProps,
  "errorMessage" | "isLoading" | "handleSubmit" | "handleClose"
>;

const useSubmitDialog = (params: {
  handleClose: () => void;
  appointment: Appointment | null;
  center: Center | null;
  selectedDate: Date | null;
  selectedTime: Date | null;
  overriddenTime: string | null;
  onTimeOverride: (time: string | null) => void;
  resetForm: () => void;
  handleChangeAppointmentStartedAt: (
    startCenterTimezone: Date,
  ) => void | Promise<void>;
}): Result => {
  const {
    handleClose,
    appointment,
    center,
    selectedDate,
    selectedTime,
    overriddenTime,
    onTimeOverride,
    resetForm,
    handleChangeAppointmentStartedAt,
  } = params;

  const { translations } = useTranslations();
  const { runAsyncFunction } = useDefaultErrorWrapper();

  const rescheduleAppointment = useFirebaseAppFunction("rescheduleAppointment");

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onClose = useCallback(() => {
    handleClose();
    resetForm();
    setErrorMessage(null);
  }, [handleClose, resetForm]);

  const handleSubmit = useCallback(async () => {
    const {
      appointmentInPastError,
      statusNotSupportedError,
      defaultError,
      timeSlotNotAvailableError,
    } = translations;

    setIsLoading(true);
    try {
      if (
        !appointment ||
        !center ||
        !selectedDate ||
        (!selectedTime && !overriddenTime)
      ) {
        throw new Error(defaultError);
      }

      let hours = 0;
      let minutes = 0;

      if (selectedTime) {
        hours = getHours(selectedTime);
        minutes = getMinutes(selectedTime);
      }

      if (overriddenTime) {
        [hours, minutes] = overriddenTime.split(":").map(Number);
      }

      const startedAtCenterTimezone = set(selectedDate, {
        hours,
        minutes,
        seconds: 0,
        milliseconds: 0,
      });

      const startedAtUTC = convertZonedDateToUtcDateString({
        date: startedAtCenterTimezone,
        timezone: center.timezone,
      });

      const { data } = await runAsyncFunction(rescheduleAppointment, {
        appointmentId: appointment.id,
        startedAtUTC,
      });

      if (data.status === "error") {
        const { code } = data.error;

        switch (code) {
          case "APPOINTMENT_IN_PAST": {
            throw new Error(appointmentInPastError);
          }
          case "STATUS_NOT_SUPPORTED": {
            throw new Error(statusNotSupportedError);
          }
          case "TIME_SLOT_NOT_AVAILABLE": {
            throw new Error(timeSlotNotAvailableError);
          }
          default: {
            throw new Error(defaultError);
          }
        }
      }

      handleChangeAppointmentStartedAt(selectedDate);
      onTimeOverride(null);
      onClose();
    } catch (error) {
      setErrorMessage((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  }, [
    translations,
    appointment,
    center,
    selectedDate,
    selectedTime,
    overriddenTime,
    runAsyncFunction,
    rescheduleAppointment,
    handleChangeAppointmentStartedAt,
    onTimeOverride,
    onClose,
  ]);

  return {
    errorMessage,
    isLoading,
    handleSubmit,
    handleClose: onClose,
  };
};

export default useSubmitDialog;
