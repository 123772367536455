import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";

import { EventInput } from "../../Unknown/Calendar";
import { SupabaseCalendarTimeSlot } from "./getCalendarTimeSlots";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";

type ConvertTimeSlotsToEventsTranslations = {
  left: string;
  right: string;
  errorAppointmentUndefined: string;
  errorPatientDetailsUndefined: string;
  patientDetailsIsNotAvailable: string;
  defaultError: string;
};

export type CalendarEventExtendedProp = {
  timeSlotId: string;
  timeSlotType: DatabaseEnum["time_slot_type"];
  description?: string;
  appointmentStatus?: DatabaseEnum["appointment_status"];
};

export type CalendarGridEvent = EventInput & CalendarEventExtendedProp;

const convertTimeSlotWithAppointment = (params: {
  role?: DatabaseEnum["center_user_role"];
  timeSlot: SupabaseCalendarTimeSlot;
  translations: ConvertTimeSlotsToEventsTranslations;
}): CalendarGridEvent => {
  const { role, timeSlot, translations } = params;

  const appointment = timeSlot?.appointments?.[0];

  if (!appointment) {
    throw new Error(translations.defaultError);
  }

  const patientDetails = appointment.appointment_patient_details[0];

  const offeringNames = (appointment?.appointment_offerings || []).map(
    (offering) => {
      const sideTranslation =
        translations[(offering.side || "") as keyof typeof translations];

      if (!sideTranslation) {
        return offering.center_offering.name;
      }

      return `${offering.center_offering.name} (${sideTranslation})`;
    },
  );

  const packageNames = (appointment?.appointment_packages || []).map(
    (packageItem) => packageItem.center_package.name,
  );

  const description = [...offeringNames, ...packageNames].join(", ");

  const centerTimeZone = timeSlot.center?.timezone;

  const start = convertDbDateToCenterTZ({
    dateString: timeSlot.started_at,
    timezone: centerTimeZone,
  }).dateTimezone;

  const end = convertDbDateToCenterTZ({
    dateString: timeSlot.ended_at,
    timezone: centerTimeZone,
  }).dateTimezone;

  const title = patientDetails
    ? `${patientDetails.last_name}, ${patientDetails.first_name}`
    : translations.patientDetailsIsNotAvailable;

  return {
    title,
    start,
    end,
    description,
    appointmentStatus: appointment.status,
    textColor: "unset",
    timeSlotId: timeSlot.id,
    timeSlotType: timeSlot.type,
    centerId: timeSlot.center_id,
    id: timeSlot.id,
    ...(appointment.status !== "checkout"
      ? { url: `/${role}/appointment/${appointment.id}` }
      : {}),
  };
};

const convertTimeSlotWithoutAppointment = (params: {
  timeSlot: SupabaseCalendarTimeSlot;
}): CalendarGridEvent => {
  const { timeSlot } = params;

  const centerTimeZone = timeSlot.center?.timezone;

  const start = convertDbDateToCenterTZ({
    dateString: timeSlot.started_at,
    timezone: centerTimeZone,
  }).dateTimezone;

  const end = convertDbDateToCenterTZ({
    dateString: timeSlot.ended_at,
    timezone: centerTimeZone,
  }).dateTimezone;

  return {
    id: timeSlot.id,
    title: timeSlot.name || "",
    start,
    end,
    textColor: "unset",
    timeSlotId: timeSlot.id,
    timeSlotType: timeSlot.type,
    centerId: timeSlot.center_id,
  };
};

const convertTimeSlotsToEvents = (params: {
  role?: DatabaseEnum["center_user_role"];
  timeSlots: SupabaseCalendarTimeSlot[];
  translations: ConvertTimeSlotsToEventsTranslations;
}): CalendarGridEvent[] => {
  const { role, timeSlots, translations } = params;

  return timeSlots.map((timeSlot) => {
    if (timeSlot?.appointments?.[0]) {
      return convertTimeSlotWithAppointment({ timeSlot, translations, role });
    }

    return convertTimeSlotWithoutAppointment({ timeSlot });
  });
};

export default convertTimeSlotsToEvents;
