import compareAsc from "date-fns/compareAsc";
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping";
import min from "date-fns/min";
import max from "date-fns/max";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import clamp from "date-fns/clamp";
import { TimeSlot } from "./types";

const mergeTimeSlots = (timeSlots: TimeSlot<Date>[]) => {
  return timeSlots
    .sort((a, b) => compareAsc(a.startedAt, b.startedAt))
    .reduce<TimeSlot<Date>[]>((acc, currentTimeSlot) => {
      if (!acc.length) return [currentTimeSlot];

      const prevTimeSlot = acc.pop() as TimeSlot<Date>;
      if (
        areIntervalsOverlapping(
          {
            start: currentTimeSlot.startedAt,
            end: currentTimeSlot.endedAt,
          },
          {
            start: prevTimeSlot.startedAt,
            end: prevTimeSlot.endedAt,
          },
          { inclusive: true },
        )
      ) {
        const nextUnavailable = {
          startedAt: min([currentTimeSlot.startedAt, prevTimeSlot.startedAt]),
          endedAt: max([currentTimeSlot.endedAt, prevTimeSlot.endedAt]),
        };

        return [...acc, nextUnavailable];
      }

      return [...acc, prevTimeSlot, currentTimeSlot];
    }, []);
};

const getNextTimeSlot = (params: {
  current?: TimeSlot<Date>;
  lastEnd: Date;
  endOfDate: Date;
}) => {
  const { current, lastEnd, endOfDate } = params;

  if (!current) {
    const nextTimeSlot = { startedAt: lastEnd, endedAt: endOfDate };

    return { nextTimeSlot, nextLastEnd: endOfDate };
  }

  const nextLastEnd = current.endedAt;

  if (isAfter(current.startedAt, lastEnd)) {
    const nextTimeSlot = { startedAt: lastEnd, endedAt: current.startedAt };

    return { nextTimeSlot, nextLastEnd };
  }

  return { nextLastEnd };
};

const reverseTimeSlots = (params: {
  startOfDate: Date;
  endOfDate: Date;
  timeSlots: TimeSlot<Date>[];
}): TimeSlot<Date>[] => {
  const { startOfDate, endOfDate, timeSlots } = params;

  const clampOptions = { start: startOfDate, end: endOfDate };

  const clampedTimeSlots = timeSlots.map<TimeSlot<Date>>((timeslot) => {
    return {
      startedAt: clamp(timeslot.startedAt, clampOptions),
      endedAt: clamp(timeslot.endedAt, clampOptions),
    };
  });

  const merged = mergeTimeSlots(clampedTimeSlots);

  let lastEnd: Date = startOfDate;
  let idx = 0;

  const reversedTimeSlots: TimeSlot<Date>[] = [];

  while (isBefore(lastEnd, endOfDate)) {
    const current = merged[idx];
    idx += 1;

    const { nextTimeSlot, nextLastEnd } = getNextTimeSlot({
      current,
      lastEnd,
      endOfDate,
    });

    if (nextTimeSlot) {
      reversedTimeSlots.push(nextTimeSlot);
    }

    lastEnd = nextLastEnd;
  }

  return reversedTimeSlots;
};

export default reverseTimeSlots;
