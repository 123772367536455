import addMilliseconds from "date-fns/addMilliseconds";
import differenceInMinutes from "date-fns/differenceInMinutes";
import max from "date-fns/max";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import getIntervalOperatingHours from "./getIntervalOperatingHours";
import reverseTimeSlots from "./reverseTimeSlots";
import { AvailableTimeSlots, OperatingHours, TimeSlot } from "./types";
import { convertDbDateToCenterTZ } from "../../common/dateHelpers";

const getAvailableTimeSlots = (params: {
  startDate: Date;
  endDate: Date;
  operatingHours: OperatingHours;
  centerTimeSlots: TimeSlot<string>[];
  timezoneOffsetMillis: number;
  appointmentDurationMinutes: number;
  timezone: string;
}) => {
  const {
    startDate,
    endDate,
    operatingHours,
    centerTimeSlots,
    timezoneOffsetMillis,
    appointmentDurationMinutes,
    timezone,
  } = params;

  const startDateTimezone = addMilliseconds(startDate, timezoneOffsetMillis);
  const endDateTimezone = addMilliseconds(endDate, timezoneOffsetMillis);

  const workingDaysData = getIntervalOperatingHours({
    startDateTimezone,
    endDateTimezone: max([startDateTimezone, endDateTimezone]),
    operatingHours,
  });

  const unavailableTimeSlotsTimezone = centerTimeSlots.map((centerTimeSlot) => {
    return {
      startedAt: convertDbDateToCenterTZ({
        dateString: centerTimeSlot.startedAt,
        timezone,
      }).dateTimezone,
      endedAt: convertDbDateToCenterTZ({
        dateString: centerTimeSlot.endedAt,
        timezone,
      }).dateTimezone,
    };
  });

  const availableTimeSlotsWorkingDays = workingDaysData.map((workingDay) => {
    const availableTimeSlots = reverseTimeSlots({
      startOfDate: workingDay.startOfOperatingDayTimezone,
      endOfDate: workingDay.endOfOperatingDayTimezone,
      timeSlots: unavailableTimeSlotsTimezone,
    });

    const maxDurationMinutes = availableTimeSlots.reduce((localMax, curr) => {
      const currDuration = differenceInMinutes(curr.endedAt, curr.startedAt);
      return Math.max(localMax, currDuration);
    }, 0);

    const result = {
      day: workingDay.day,
      availableTimeSlots,
      maxDurationMinutes,
    };

    return result;
  });

  const availableTimeSlotsForDuration = availableTimeSlotsWorkingDays.map(
    (availableTimeSlotsWorkingDay) => {
      const available = availableTimeSlotsWorkingDay.availableTimeSlots
        .map((availableTimeSlotWorkingDay) => {
          if (!appointmentDurationMinutes) return [];
          const intervals = eachMinuteOfInterval(
            {
              start: availableTimeSlotWorkingDay.startedAt,
              end: availableTimeSlotWorkingDay.endedAt,
            },
            { step: appointmentDurationMinutes },
          );
          intervals.splice(-1);
          return intervals;
        })
        .flat();

      return {
        day: availableTimeSlotsWorkingDay.day,
        maxDurationMinutes: availableTimeSlotsWorkingDay.maxDurationMinutes,
        available,
      };
    },
  );

  const result = availableTimeSlotsForDuration.reduce<AvailableTimeSlots>(
    (acc, dayData) => {
      const { day, maxDurationMinutes, available } = dayData;
      return { ...acc, [day]: { maxDurationMinutes, available } };
    },
    {},
  );

  return result;
};

export default getAvailableTimeSlots;
