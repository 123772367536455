import { alpha, lighten, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme>(({ spacing, shape, palette }) => {
  return {
    btnEdit: {
      paddingRight: spacing(2),
      paddingLeft: spacing(2),
    },
    paperRoot: {
      maxHeight: `min(350px, 85vh)`,
    },
    groupRoot: {
      backgroundColor: lighten(palette.primary.main, 0.9),
    },
    menuItemRoot: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: spacing(2),
      paddingRight: spacing(3),
      paddingBottom: spacing(2),
      paddingLeft: spacing(3),
    },
    oldPriceTextRoot: {
      color: palette.error.main,
      textDecoration: "line-through",
    },
    addInputButton: {
      justifyContent: "start",
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
      paddingRight: spacing(4),
      paddingLeft: spacing(4),
      color: palette.action.active,
      border: "1px dashed",
    },
    addButtonIcon: {
      marginRight: spacing(3),
      "& *:nth-of-type(1)": {
        fontSize: "24px",
      },
    },
    summaryRoot: {
      border: `1px solid ${alpha(palette.common.black, 0.23)}`,
      borderRadius: shape.borderRadius,
      overflowY: "auto",
      maxHeight: "inherit",
      padding: spacing(6),
    },
  };
});

export default useStyles;
