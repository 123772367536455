import Big from "big.js";

export type SummaryPosition = {
  name: string;
  price: number;
};

const getTotalSummary = (params: {
  centerServices: SummaryPosition[];
  discountAmount?: number;
  addOns: SummaryPosition[];
}) => {
  const { centerServices, discountAmount, addOns } = params;

  const centerServicesPriceBig = centerServices.reduce(
    (sum, centerService) => sum.plus(centerService.price),
    Big(0),
  );

  const addOnsPriceBig = addOns.reduce(
    (sum, addOn) => sum.plus(addOn.price),
    Big(0),
  );

  const price = centerServicesPriceBig.plus(addOnsPriceBig).round(2).toNumber();

  const additionalServicesLength = Math.max(centerServices.length - 1, 0);

  const discountSum = discountAmount
    ? Big(discountAmount).times(additionalServicesLength).toNumber()
    : 0;

  const priceWithDiscount = Big(price).minus(discountSum).toNumber();

  return {
    price,
    discountSum,
    priceWithDiscount,
  };
};

export default getTotalSummary;
