import React, { FC, useMemo, ChangeEvent, useCallback } from "react";

import CheckboxGroup from "../../Unknown/CheckboxGroup";

import { AddOn, AddOnsState, CenterConfig } from "./types";
import useTranslations from "./translations";
import parseAddOn from "./parseAddOn";

export interface SelectAddOnsFormProps {
  addOns: AddOn[] | null;
  centerConfig: CenterConfig;
  selectedAddOns: AddOnsState;
  isSubmitting: boolean;
  onAddOnsChange: React.Dispatch<React.SetStateAction<AddOnsState>>;
}

const SelectAddOnsForm: FC<SelectAddOnsFormProps> = ({
  addOns,
  centerConfig,
  selectedAddOns,
  isSubmitting,
  onAddOnsChange,
}) => {
  const { translations } = useTranslations();

  const options = useMemo(() => {
    if (!centerConfig || !addOns?.length) return [];

    const { defaultCurrencyCode } = centerConfig;

    return addOns.map((addOn) => {
      const checked = selectedAddOns.some(
        (selected) => selected.id === addOn.id,
      );

      return parseAddOn(addOn, defaultCurrencyCode, checked);
    });
  }, [addOns, centerConfig, selectedAddOns]);

  const onChecked = useCallback(
    (checkedValue?: string): void => {
      if (!centerConfig || !addOns?.length) return;

      if (!checkedValue) return;

      const selectedAddOn = addOns.find((addOn) => addOn.id === checkedValue);

      if (!selectedAddOn) return;

      onAddOnsChange((prev) => [...prev, selectedAddOn]);
    },
    [addOns, centerConfig, onAddOnsChange],
  );

  const onUnchecked = useCallback(
    (uncheckedValue?: string) => {
      if (!uncheckedValue) return;

      const indexOfValue = selectedAddOns.findIndex(
        (addOn) => addOn.id === uncheckedValue,
      );

      const isValueInSelectedItems = indexOfValue !== -1;

      if (!isValueInSelectedItems) return;

      onAddOnsChange((prev) => {
        const newAddOns = [...prev];
        newAddOns.splice(indexOfValue, 1);
        return newAddOns;
      });
    },
    [onAddOnsChange, selectedAddOns],
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const actionFunc = checked ? onChecked : onUnchecked;
      actionFunc(e?.target?.value);
    },
    [onChecked, onUnchecked],
  );

  if (!centerConfig || !addOns?.length) return null;

  return (
    <CheckboxGroup
      title={translations.selectAdOnsTitle}
      options={options}
      onChange={onChange}
      disabled={isSubmitting}
    />
  );
};

export default SelectAddOnsForm;
