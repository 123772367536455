import React from "react";
import getFormattedPrice from "../../../../common/getFormattedPrice";
import Box from "../../../Unknown/Box";
import Typography from "../../../Unknown/Typography";
import { GridColDef } from "../../../Unknown/DataGrid";
import { AppointmentRefund } from "../types";

type ColumnTranslations = {
  methodCash: string;
  methodCard: string;
  headerRefund: string;
};

type ColumnParams = {
  translations: ColumnTranslations;
};

const getMethodTranslationKey = (
  method?: string,
): keyof ColumnTranslations | null => {
  switch (method) {
    case "cash":
      return "methodCash";
    case "card":
    case "card_present":
      return "methodCard";
    default:
      return null;
  }
};

const getColumnRefund = ({
  translations,
}: ColumnParams): GridColDef<AppointmentRefund, string> => {
  const { headerRefund } = translations;

  return {
    field: "amount",
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: headerRefund,
    renderCell: ({ row }) => {
      const { amount, method, currencyCode, intl, paymentMethodLabel } = row;

      const methodTranslationKey = getMethodTranslationKey(method);

      const formattedAmount = getFormattedPrice({
        value: amount,
        currencyCode,
        intl,
      });

      const methodText = methodTranslationKey
        ? translations[methodTranslationKey]
        : "";

      return (
        <Box>
          <Typography variant="body2" color="text.primary">
            {formattedAmount}
          </Typography>
          <Typography variant="body2" fontSize={13} color="text.secondary">
            {paymentMethodLabel || methodText}
          </Typography>
        </Box>
      );
    },
  };
};

export default getColumnRefund;
