import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { GridColDef } from "../../Unknown/DataGrid";
import getColumnOptions from "./columns/getColumnOptions";
import getColumnStudies from "./columns/getColumnStudies";
import getColumnName from "./columns/getColumnName";
import { CenterService } from "./types";
import useTranslations from "./useTranslations";
import useStyles from "./useStyles";

const useColumns = (params: {
  onAddAdditionalViewClick: (id: string) => void;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
}): GridColDef<CenterService>[] => {
  const { onAddAdditionalViewClick, onReadStudyClick, onEditStudyClick } =
    params;

  const { hiddenHeaderSeparatorRoot } = useStyles();
  const { role } = useParams();

  const translations = useTranslations();

  const columns = useMemo(() => {
    const {
      headerName,
      headerStudies,
      sideLeft,
      sideRight,
      btnAdditionalViewsRequired,
      btnEdit,
      btnRead,
      btnContinueReading,
    } = translations;

    return [
      getColumnName({
        translations: { headerName, sideLeft, sideRight },
      }),
      getColumnStudies({
        translations: { headerStudies },
        headerClassName: hiddenHeaderSeparatorRoot,
      }),
      getColumnOptions({
        translations: {
          btnAdditionalViewsRequired,
          btnEdit,
          btnRead,
          btnContinueReading,
        },
        role,
        headerClassName: hiddenHeaderSeparatorRoot,
        onAddAdditionalViewClick,
        onReadStudyClick,
        onEditStudyClick,
      }),
    ];
  }, [
    translations,
    hiddenHeaderSeparatorRoot,
    role,
    onAddAdditionalViewClick,
    onReadStudyClick,
    onEditStudyClick,
  ]);

  return columns;
};

export default useColumns;
