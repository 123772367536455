import { useEffect } from "react";
import useDataState from "../../../hooks/useDataState";
import { CenterServices, CenterConfig, Offering, Package } from "./types";
import useOfferings from "./useOfferings";
import usePackages from "./usePackages";

export const parseCenterServices = (params: {
  offerings: Offering[];
  packages: Package[];
}): CenterServices => {
  const { offerings, packages } = params;

  const offeringCenterServices = offerings.reduce<CenterServices>(
    (acc, offering) => ({
      ...acc,
      [offering.id]: {
        ...offering,
        groupId: "single-offering",
      },
    }),
    {},
  );

  const packageCenterServices = packages.reduce<CenterServices>(
    (acc, packageItem) => ({
      ...acc,
      [packageItem.id]: {
        ...packageItem,
        groupId: "package",
      },
    }),
    {},
  );

  return { ...offeringCenterServices, ...packageCenterServices };
};

const useCenterServices = ({
  centerConfig,
}: {
  centerConfig?: CenterConfig;
}) => {
  const offerings = useOfferings({ centerConfig });
  const packages = usePackages({ centerConfig });

  const [dataState, updateDataState] = useDataState<CenterServices>();

  useEffect(() => {
    const {
      data: offeringsData,
      status: offeringsStatus,
      error: offeringsError,
    } = offerings;

    const {
      data: packagesData,
      status: packagesStatus,
      error: packagesError,
    } = packages;

    try {
      if (offeringsStatus === "error") {
        updateDataState({ status: "error", error: offeringsError });
        return;
      }

      if (packagesStatus === "error") {
        updateDataState({ status: "error", error: packagesError });
        return;
      }

      if (packagesStatus === "loading" || offeringsStatus === "loading") {
        updateDataState({ status: "loading" });
        return;
      }

      const data = parseCenterServices({
        offerings: offeringsData,
        packages: packagesData,
      });

      updateDataState({ status: "success", data });
    } catch (error) {
      updateDataState({ status: "error", error: (error as Error).message });
    }
  }, [offerings, packages, updateDataState]);

  return { dataState, offerings, packages };
};

export default useCenterServices;
