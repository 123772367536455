import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";
import { AppointmentPreviewCardContainerProps } from "../AppointmentPreviewCardContainer";
import useSystemConfig from "../../../hooks/useSystemConfig";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import useDefaultErrorWrapper from "../../../hooks/useDefaultErrorWrapper";
import { AdditionalAppointment } from "../AppointmentPreviewCard/types";
import { DEFAULT_CURRENCY_CODE } from "../../../common/constants";
import { Appointment, AppointmentOffering } from "./types";

type PreviewCardAppointment =
  AppointmentPreviewCardContainerProps["appointment"];
type Offerings = PreviewCardAppointment["offerings"];
type Packages = PreviewCardAppointment["packages"];
type AddOns = PreviewCardAppointment["addOns"];
type Customer = PreviewCardAppointment["customer"];

const useAppointmentPreviewCardContainer = (params: {
  appointment: Appointment | null;
  refetch: () => Promise<void>;
}): AppointmentPreviewCardContainerProps | null => {
  const { appointment, refetch } = params;

  const { runAsyncFunction } = useDefaultErrorWrapper();

  const getPatientFormSignedUrl = useFirebaseAppFunction(
    "getAppointmentFormSignedUrl",
  );

  const { dataState } = useSystemConfig();

  const { role, appointmentId } = useParams();

  const propsAppointment = useMemo(() => {
    if (!appointment) return null;

    const {
      id,
      created_at,
      center,
      status,
      center_time_slot,
      appointment_offerings,
      appointment_packages,
      appointment_add_ons,
      appointment_customer_details,
      is_stat,
      is_additional_views,
      additional_views_appointments,
      original_appointments,
    } = appointment;
    const { name, timezone, region } = center;
    const { started_at } = center_time_slot;

    const { dateTimezone: startedAtCenterTimezone } = convertDbDateToCenterTZ({
      dateString: started_at,
      timezone,
    });

    const additionalViewsAppointment: AdditionalAppointment | null =
      additional_views_appointments[0]?.appointment_view_id
        ? {
            startedAt: convertDbDateToCenterTZ({
              dateString:
                additional_views_appointments[0]?.appointment_view_id
                  .time_slot_id.started_at,
              timezone,
            }).dateTimezone,
            link: `/${role}/appointment/${additional_views_appointments[0]?.appointment_view_id.id}`,
          }
        : null;

    const originalAppointment: AdditionalAppointment | null =
      original_appointments[0]?.appointment_id
        ? {
            startedAt: convertDbDateToCenterTZ({
              dateString:
                original_appointments[0]?.appointment_id.time_slot_id
                  .started_at,
              timezone,
            }).dateTimezone,
            link: `/${role}/appointment/${original_appointments[0]?.appointment_id.id}`,
          }
        : null;

    const { dateTimezone: createdAtCenterTimezone } = convertDbDateToCenterTZ({
      dateString: created_at,
      timezone,
    });

    const { dateTimezone: bookedAtCenterTimezone } = convertDbDateToCenterTZ({
      dateString: center_time_slot.started_at,
      timezone,
    });

    const offerings: Offerings = appointment_offerings.map(
      (appointmentOffering) => {
        return {
          id: appointmentOffering.id,
          acc: appointmentOffering.acc,
          name: appointmentOffering.center_offering.name,
          side: appointmentOffering.side || undefined,
          status: appointmentOffering.status,
          pacsUrl: appointmentOffering.pacs_url || undefined,
          centerOfferingId: appointmentOffering.center_offering.id,
          modalityName:
            appointmentOffering.modality_name ||
            appointmentOffering.center_offering.name,
        };
      },
    );

    const packages: Packages = appointment_packages.map(
      (appointmentPackage) => {
        const packageOfferings = appointmentPackage.appointment_offerings.map(
          (appointmentOffering) => ({
            id: appointmentOffering.id,
            name: appointmentOffering.center_offering.name,
            acc: appointmentOffering.acc,
            status: appointmentOffering.status,
            pacsUrl: appointmentOffering.pacs_url || undefined,
            centerOfferingId: appointmentOffering.center_offering.id,
            modalityName:
              appointmentOffering.modality_name ||
              appointmentOffering.center_offering.modality_name,
          }),
        );
        return {
          id: appointmentPackage.id,
          centerPackageId: appointmentPackage.center_package.id,
          name: appointmentPackage.center_package.name,
          offerings: packageOfferings,
        };
      },
    );

    const addOns: AddOns = appointment_add_ons.map((appointmentAddOn) => {
      return {
        id: appointmentAddOn.id,
        centerAddOnId: appointmentAddOn.center_add_on.id,
        name: appointmentAddOn.center_add_on.name,
      };
    });

    const customerDetails = appointment_customer_details[0];

    const customerId =
      customerDetails.type === "other_person"
        ? customerDetails.id
        : customerDetails.customer_id;

    const screeningFormFileName = appointment.appointment_forms.find(
      (appointmentForm) => appointmentForm.type === "screening",
    )?.file_name;

    const onScreeningFormDownloadClick =
      screeningFormFileName && appointmentId
        ? async () => {
            const { data } = await runAsyncFunction(getPatientFormSignedUrl, {
              fileName: screeningFormFileName,
              appointmentId,
            });

            window.open(data.url, "_blank");
          }
        : null;

    const customer: Customer = {
      firstName: customerDetails.first_name,
      lastName: customerDetails.last_name,
      link: `/${role}/appointment/${appointmentId}/customer/${customerId}`,
    };

    const packagesOfferings = appointment_packages.reduce(
      (acc, appointmentPackage) => {
        return [...acc, ...appointmentPackage.appointment_offerings];
      },
      [] as AppointmentOffering[],
    );

    const hasUnexpectedFindings = [
      ...appointment_offerings,
      ...packagesOfferings,
    ].some(({ has_unexpected_findings }) => has_unexpected_findings);

    const isUnexpectedFindingsReceiptConfirmed =
      hasUnexpectedFindings &&
      [...appointment_offerings, ...packagesOfferings].every(
        ({ has_unexpected_findings, is_receipt_confirmed }) =>
          !has_unexpected_findings ||
          (has_unexpected_findings && is_receipt_confirmed),
      );

    const result: PreviewCardAppointment = {
      id,
      createdAtCenterTimezone,
      bookedAtCenterTimezone,
      isStat: is_stat || false,
      isAdditionalViews: is_additional_views || false,
      hasUnexpectedFindings,
      isUnexpectedFindingsReceiptConfirmed,
      timeSlot: {
        startedAtCenterTimezone,
      },
      center: {
        id: center.id,
        name,
        timezone,
        locale: region,
        defaultOfferingDurationMinutes:
          center.center_configs[0]?.default_offering_duration_minutes || 0,
        discountAmount: center.center_configs[0]?.discount_amount || 0,
        defaultCurrencyCode:
          center.center_configs[0]?.default_currency_code ||
          DEFAULT_CURRENCY_CODE,
      },
      hasPriority: appointment.has_priority || false,
      offerings,
      packages,
      addOns,
      customer,
      originalAppointment,
      status,
      additionalViewsAppointment,
      onScreeningFormDownloadClick,
    };

    return result;
  }, [
    role,
    appointment,
    appointmentId,
    runAsyncFunction,
    getPatientFormSignedUrl,
  ]);

  const appointmentOfferingsProp = useMemo<
    AppointmentPreviewCardContainerProps["appointmentOfferings"]
  >(() => {
    if (!appointment || !dataState.data) return [];

    return appointment.appointment_offerings.map((appointmentOffering) => {
      return {
        centerOfferingId: appointmentOffering.center_offering.id,
        offeringId: appointmentOffering.id,
        side: appointmentOffering.side as "left" | "right" | null,
        modalityName:
          appointmentOffering.modality_name ||
          appointmentOffering.center_offering.name,
        scanSide: appointmentOffering.center_offering.scan_side,
        price: appointmentOffering.total_cost_amount,
        durationMinutes:
          appointmentOffering.center_offering.duration_minutes ??
          dataState.data.default_offering_duration_minutes,
      };
    });
  }, [appointment, dataState.data]);

  if (!propsAppointment) {
    return null;
  }

  return {
    appointment: propsAppointment,
    appointmentOfferings: appointmentOfferingsProp,
    refetch,
  };
};

export default useAppointmentPreviewCardContainer;
