import { useMemo } from "react";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";
import { AppointmentFileGridContainerProps } from "../../AppointmentFile/AppointmentFileGridContainer";
import { Appointment } from "./types";

type InitialFile = AppointmentFileGridContainerProps["initialFiles"][number];

const useAppointmentFileGridContainer = (appointment: Appointment | null) => {
  const props = useMemo<AppointmentFileGridContainerProps | null>(() => {
    if (!appointment) return null;

    const { appointment_files, center } = appointment;

    const initialFiles = appointment_files.map<InitialFile>((file) => {
      const { id, name, url, created_at } = file;

      const uploadedAt = convertDbDateToCenterTZ({
        dateString: created_at,
        timezone: center.timezone,
      });

      return {
        id,
        name,
        downloadUrl: url,
        uploadedAt: uploadedAt.dateTimezone,
      };
    });

    return {
      initialFiles,
      appointmentId: appointment.id,
    };
  }, [appointment]);

  return props;
};

export default useAppointmentFileGridContainer;
