import endOfMonth from "date-fns/endOfMonth";
import max from "date-fns/max";
import min from "date-fns/min";
import startOfMonth from "date-fns/startOfMonth";
import subMilliseconds from "date-fns/subMilliseconds";
import { useMemo } from "react";
import objectKeys from "../../../common/objectKeys";
import getAvailableTimeSlots from "../../../helpers/appointmentOperatingHours/getAvailableTimeSlots";
import {
  AvailableTimeSlots,
  CenterTimeSlot,
  Center,
  Appointment,
} from "./types";

const useAvailableTimeSlots = (params: {
  monthDate: Date;
  center: Center | null;
  appointment: Appointment | null;
  centerTimeSlots: CenterTimeSlot[] | null;
}) => {
  const { monthDate, appointment, center, centerTimeSlots } = params;

  const availableTimeSlots = useMemo<AvailableTimeSlots>(() => {
    if (!center || !centerTimeSlots || !appointment) return {};

    const { dateLimits, operatingHours, timezoneOffsetMillis } = center;

    const timeSlots = centerTimeSlots.map((timeSlot) => {
      return {
        startedAt: timeSlot.started_at,
        endedAt: timeSlot.ended_at,
      };
    });

    const startMonthCenterTimezone = subMilliseconds(
      startOfMonth(monthDate),
      timezoneOffsetMillis,
    );

    const endMonthCenterTimezone = subMilliseconds(
      endOfMonth(monthDate),
      timezoneOffsetMillis,
    );

    const availableTimeSlotsData = getAvailableTimeSlots({
      startDate: max([startMonthCenterTimezone, dateLimits.minDateTimeUTC]),
      endDate: min([endMonthCenterTimezone, dateLimits.maxDateTimeUTC]),
      operatingHours,
      centerTimeSlots: timeSlots,
      timezoneOffsetMillis,
      appointmentDurationMinutes: appointment.durationMinutes,
      timezone: center.timezone,
    });

    const result = objectKeys(
      availableTimeSlotsData,
    ).reduce<AvailableTimeSlots>((acc, dayKey) => {
      const { available } = availableTimeSlotsData[dayKey];

      if (!available.length) {
        return acc;
      }
      return { ...acc, [dayKey]: available };
    }, {});

    return result;
  }, [center, centerTimeSlots, appointment, monthDate]);

  return availableTimeSlots;
};

export default useAvailableTimeSlots;
