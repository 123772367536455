import { defineMessages } from "react-intl";

const commonMessages = defineMessages({
  defaultError: {
    id: "common.messages.defaultError",
    defaultMessage: "Unknown error",
  },
  requiredField: {
    id: "common.messages.requiredField",
    defaultMessage: "Required field",
  },
  maxStringLengthError: {
    id: "common.messages.maxStringLengthError",
    defaultMessage: "Value must be at most {value} characters",
  },
  shortMonday: {
    id: "common.messages.shortMonday",
    defaultMessage: "Mon",
  },
  shortTuesday: {
    id: "common.messages.shortTuesday",
    defaultMessage: "Tue",
  },
  shortWednesday: {
    id: "common.messages.shortWednesday",
    defaultMessage: "Wed",
  },
  shortThursday: {
    id: "common.messages.shortThursday",
    defaultMessage: "Thu",
  },
  shortFriday: {
    id: "common.messages.shortFriday",
    defaultMessage: "Fri",
  },
  shortSaturday: {
    id: "common.messages.shortSaturday",
    defaultMessage: "Sat",
  },
  shortSunday: {
    id: "common.messages.shortSunday",
    defaultMessage: "Sun",
  },
  longMonday: {
    id: "common.messages.longMonday",
    defaultMessage: "Monday",
  },
  longTuesday: {
    id: "common.messages.longTuesday",
    defaultMessage: "Tuesday",
  },
  longWednesday: {
    id: "common.messages.longWednesday",
    defaultMessage: "Wednesday",
  },
  longThursday: {
    id: "common.messages.longThursday",
    defaultMessage: "Thursday",
  },
  longFriday: {
    id: "common.messages.longFriday",
    defaultMessage: "Friday",
  },
  longSaturday: {
    id: "common.messages.longSaturday",
    defaultMessage: "Saturday",
  },
  longSunday: {
    id: "common.messages.longSunday",
    defaultMessage: "Sunday",
  },
  edit: {
    id: "common.messages.edit",
    defaultMessage: "Edit",
  },
  remove: {
    id: "common.messages.remove",
    defaultMessage: "Remove",
  },
  left: {
    id: "common.messages.left",
    defaultMessage: "left",
  },
  right: {
    id: "common.messages.right",
    defaultMessage: "right",
  },
  discount: {
    id: "common.messages.discount",
    defaultMessage: "Discount",
  },
  total: {
    id: "common.messages.total",
    defaultMessage: "Total",
  },
});

export default commonMessages;
