import { useMemo } from "react";
import { StatusDialogProps } from "../../Unknown/StatusDialog";
import useTranslations from "./translations";

const useActionButtons = (params: {
  handleClose: () => void;
  handleSubmit?: () => void | Promise<void>;
  isLoading?: boolean;
  errorMessage?: string | null;
  isValid?: boolean;
}) => {
  const { handleClose, handleSubmit, isLoading, errorMessage, isValid } =
    params;
  const { translations } = useTranslations();

  const { btnCancel, btnSubmit } = translations;

  const actionButtons = useMemo<StatusDialogProps["actionButtons"]>(() => {
    return [
      {
        text: btnCancel,
        key: "cancel",
        onClick: handleClose,
        disabled: isLoading,
      },
      ...(handleSubmit
        ? [
            {
              text: btnSubmit,
              key: "submit",
              onClick: handleSubmit,
              disabled: isLoading || !isValid || !!errorMessage,
            },
          ]
        : []),
    ];
  }, [
    btnCancel,
    btnSubmit,
    errorMessage,
    handleClose,
    handleSubmit,
    isLoading,
    isValid,
  ]);

  return actionButtons;
};

export default useActionButtons;
