import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsAdditionalViewsCanBeBookedParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsAdditionalViewsCanBeBooked = ({
  appointment,
  role,
}: CheckIsAdditionalViewsCanBeBookedParams): boolean => {
  const isOfferingAdditionalViewsRequired =
    appointment?.appointment_offerings?.some(
      (appointmentOffering) => appointmentOffering.is_additional_views_required,
    );

  const packageAdditionalViewsRequired =
    appointment?.appointment_packages?.some((appointmentPackage) =>
      appointmentPackage.appointment_offerings.some(
        (appointmentOffering) =>
          appointmentOffering.is_additional_views_required,
      ),
    );

  return !!(
    appointment &&
    !appointment.is_additional_views &&
    role &&
    ["technologist", "super_admin", "regional_admin", "receptionist"].includes(
      role,
    ) &&
    (isOfferingAdditionalViewsRequired || packageAdditionalViewsRequired)
  );
};

export default checkIsAdditionalViewsCanBeBooked;
