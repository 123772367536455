import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";
import { Appointment } from "../AppointmentCardsContainer/types";

interface CheckIsCanBeCanceledParams {
  appointment: Appointment | null;
  role?: DatabaseEnum["center_user_role"];
}

const checkIsCanBeCanceled = ({
  appointment,
  role,
}: CheckIsCanBeCanceledParams): boolean => {
  return !!(
    appointment &&
    role &&
    ["technologist", "super_admin", "regional_admin", "receptionist"].includes(
      role,
    ) &&
    ["confirmed", "intake_complete", "checked_in", "studies_unread"].includes(
      appointment.status,
    )
  );
};

export default checkIsCanBeCanceled;
