import { UpdateCenterOperatingHoursParams } from "@Shape-Digital/kudzu-data/lib/types/actions";
import differenceInMinutes from "date-fns/differenceInMinutes";
import startOfDay from "date-fns/startOfDay";
import objectKeys from "../../../common/objectKeys";
import { CenterOperatingHoursFormValues } from "../CenterOperatingHoursForm";

const getDayMinutes = (date: Date) => {
  const startOfDate = startOfDay(date);

  return differenceInMinutes(date, startOfDate);
};

type Keys = keyof UpdateCenterOperatingHoursParams["operatingHours"];
type DayValue = UpdateCenterOperatingHoursParams["operatingHours"][Keys];

const normalizeFormikValues = (
  values: CenterOperatingHoursFormValues,
): UpdateCenterOperatingHoursParams["operatingHours"] => {
  const { operatingHours } = values;

  const result = objectKeys(operatingHours).reduce<
    UpdateCenterOperatingHoursParams["operatingHours"]
  >((acc, dayKey): UpdateCenterOperatingHoursParams["operatingHours"] => {
    const { isOpen, fromMinutesAt, toMinutesAt } = operatingHours[dayKey];

    if (!isOpen || !fromMinutesAt || !toMinutesAt) return acc;

    const fromMinutesAtCenterTimezone = getDayMinutes(fromMinutesAt);

    const toMinutesAtCenterTimezone = getDayMinutes(toMinutesAt);

    const dayValue: DayValue = {
      fromMinutesAtUTC: fromMinutesAtCenterTimezone,
      toMinutesAtUTC: toMinutesAtCenterTimezone,
    };

    return { ...acc, [dayKey]: dayValue };
  }, {});

  return result;
};

export default normalizeFormikValues;
