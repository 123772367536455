import { DatabaseEnum } from "@Shape-Digital/kudzu-data/lib/types/common";

import { Appointment } from "./types";

const checkIsCanBeModified = ({
  appointment,
  role,
}: {
  appointment: Appointment;
  role?: DatabaseEnum["center_user_role"];
}) => {
  if (
    !appointment ||
    !role ||
    !["super_admin", "regional_admin", "receptionist", "technologist"].includes(
      role,
    )
  ) {
    return false;
  }

  return !![
    "confirmed",
    "intake_complete",
    "studies_unread",
    "checked_in",
  ].includes(appointment?.status);
};

export default checkIsCanBeModified;
