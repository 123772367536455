import set from "date-fns/set";
import { useCallback, useState } from "react";
import {
  addTimeToDate,
  convertZonedDateToUtcDateString,
} from "../../../common/dateHelpers";
import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";
import { useUIContext } from "../../Unknown/UIContext";
import { TimeSlotAddDialogProps } from "../TimeSlotAddDialog";
import { TimeSlotAddFormValues } from "../TimeSlotAddDialogForm";
import useTranslations from "./useTranslations";

type OnEditOpenParams = {
  timeSlotId: string;
  name: string;
  start: Date;
  end: Date;
};

type TimeSlotEditDialogResult = TimeSlotAddDialogProps & {
  onOpen: (params: OnEditOpenParams) => void;
};

const baseInitialValues: TimeSlotAddFormValues = {
  name: "",
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
};

type TimeSlotEditParams = {
  id: string;
  title: string;
  start: Date;
  end: Date;
};

const resetTime = { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 };

const useTimeSlotEditDialog = (params: {
  onTimeSlotEdit: (value: TimeSlotEditParams) => void;
  onDeleteDialogOpen: (value: { id: string; name: string }) => void;
  centerData?: { timezone?: string | null } | null;
}): TimeSlotEditDialogResult => {
  const { onTimeSlotEdit, onDeleteDialogOpen, centerData } = params;
  const { setAlert } = useUIContext();

  const { translations, editDialogTranslations } = useTranslations();
  const { defaultError, errorTimeSlotIsAlreadyTaken } = translations;

  const editCenterTimeSlot = useFirebaseAppFunction("editCenterTimeSlot");
  const [initialValues, setInitialValues] =
    useState<TimeSlotAddFormValues>(baseInitialValues);
  const [editedTimeSlotId, setEditedTimeSlotId] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback((arg: OnEditOpenParams) => {
    const { timeSlotId, name, start, end } = arg;

    setIsOpen(true);
    setEditedTimeSlotId(timeSlotId);
    setInitialValues({
      name,
      startDate: set(start, resetTime),
      endDate: set(end, resetTime),
      startTime: start,
      endTime: end,
    });
  }, []);

  const onClose = () => setIsOpen(false);

  const onSubmit: TimeSlotAddDialogProps["onSubmit"] = useCallback(
    async (values) => {
      try {
        const { name, startDate, startTime, endDate, endTime } = values;

        if (!startDate || !startTime || !endDate || !endTime) {
          throw new Error("Some required values are not defined");
        }

        const startDateTime = addTimeToDate(startDate, startTime);
        const endDateTime = addTimeToDate(endDate, endTime);

        const startDateTimeUTC = convertZonedDateToUtcDateString({
          date: startDateTime,
          timezone: centerData?.timezone || "UTC",
        });

        const endDateTimeUTC = convertZonedDateToUtcDateString({
          date: endDateTime,
          timezone: centerData?.timezone || "UTC",
        });

        const { data: editResponse } = await editCenterTimeSlot({
          id: editedTimeSlotId,
          name,
          startDateTimeUTC,
          endDateTimeUTC,
        });

        if (editResponse.status === "error") {
          switch (editResponse.error.code) {
            case "TIMESLOT_IS_TAKEN": {
              setAlert({
                isShown: true,
                severity: "error",
                message: errorTimeSlotIsAlreadyTaken,
              });
              return;
            }

            default: {
              throw new Error(defaultError);
            }
          }
        }

        onTimeSlotEdit({
          id: editedTimeSlotId,
          title: name,
          start: startDateTime,
          end: endDateTime,
        });

        onClose();
      } catch (error) {
        setAlert({ isShown: true, severity: "error", message: defaultError });
      }
    },
    [
      centerData?.timezone,
      editCenterTimeSlot,
      editedTimeSlotId,
      onTimeSlotEdit,
      setAlert,
      errorTimeSlotIsAlreadyTaken,
      defaultError,
    ],
  );

  const onDeleteClick = useCallback(() => {
    onDeleteDialogOpen({ id: editedTimeSlotId, name: initialValues.name });
    onClose();
  }, [editedTimeSlotId, initialValues.name, onDeleteDialogOpen]);

  return {
    isOpen,
    onClose,
    onSubmit,
    initialValues,
    onOpen,
    title: editDialogTranslations.editTitle,
    onDeleteClick,
    centerTimeZone: centerData?.timezone || undefined,
  };
};

export default useTimeSlotEditDialog;
