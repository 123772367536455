import { Patient, PatientInformationDrawerData } from "./types";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";

type Result = PatientInformationDrawerData["appointmentsPreviewGridPropsProps"];

type Appointment = Result["appointments"][number];

const getAppointmentsPreviewGridPropsProps = ({
  patient,
  handleAppointmentClick,
}: {
  patient: Patient;
  handleAppointmentClick: (appointmentId: string) => void;
}): Result => {
  const appointments = patient.appointment_patient_details.map<Appointment>(
    (appointmentPatientDetails) => {
      const {
        id,
        time_slot,
        center,
        appointment_offerings,
        appointment_packages,
      } = appointmentPatientDetails.appointment;
      const { started_at } = time_slot;
      const { name, timezone, region } = center;

      const { dateTimezone: startedAt, dateUTC: startedAtUTC } =
        convertDbDateToCenterTZ({
          dateString: started_at,
          timezone,
        });

      const offerings = appointment_offerings.map((appointmentOffering) => {
        return {
          name: appointmentOffering.center_offering?.name,
          side: appointmentOffering.side,
        };
      });

      const packages = appointment_packages.map((appointmentPackage) => {
        return {
          name: appointmentPackage.center_package?.name,
        };
      });

      return {
        id,
        timeSlot: { startedAt, startedAtUTC },
        center: { name, locale: region },
        centerServices: [...offerings, ...packages],
      };
    },
  );

  return { appointments, onViewClick: handleAppointmentClick };
};

export default getAppointmentsPreviewGridPropsProps;
