import React, { FC, useState } from "react";

import { Appointment } from "../AppointmentPreviewCard/types";
import Button from "../../Unknown/Button";

import useTranslations from "./translations";
import useStyles from "./useStyles";
import AppointmentModifyServicesDialog from "./dialog";

export type AppointmentModifyServicesDialogContainerProps = {
  appointment: Appointment;
  handleSuccess: () => void | Promise<void>;
};

const AppointmentModifyServicesDialogContainer: FC<
  AppointmentModifyServicesDialogContainerProps
> = ({ appointment, handleSuccess }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { translations } = useTranslations();
  const classes = useStyles();

  const onDialogClose = () => {
    setIsOpened(false);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={() => setIsOpened(true)}
        className={classes.btnEdit}
      >
        {translations.edit}
      </Button>
      {isOpened && (
        <AppointmentModifyServicesDialog
          isOpened={isOpened}
          appointment={appointment}
          onClose={onDialogClose}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default AppointmentModifyServicesDialogContainer;
