import { useState } from "react";
import { AppointmentOffering } from "@Shape-Digital/kudzu-data/lib/types/actions";

import useFirebaseAppFunction from "../../../hooks/useFirebaseAppFunction";

import { AddOnsState, SelectedOfferingSide } from "./types";
import { Option as SelectCenterServiceInputOption } from "./input";

export const useSubmitHandler = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const editAppointmentServices = useFirebaseAppFunction(
    "editAppointmentServices",
  );

  const onSubmit = async ({
    appointmentId,
    selectedAddOns,
    selectedOptions,
  }: {
    appointmentId: string;
    selectedAddOns: AddOnsState;
    selectedOptions: (SelectCenterServiceInputOption | null)[];
  }) => {
    const selectedAddonsIds = selectedAddOns.map((addOn) => addOn.id);
    const selectedOfferings: AppointmentOffering[] = selectedOptions
      .filter((option) => !!option && option.groupId === "single-offering")
      .map((option) => ({
        id: option?.value || "",
        side: (option?.metadata?.side || null) as SelectedOfferingSide | null,
      }));

    const selectedPackagesIds = selectedOptions
      .filter((option) => !!option && option.groupId === "package")
      .map((option) => option?.value || "");

    try {
      setIsLoading(true);
      await editAppointmentServices({
        appointmentId,
        addOnsIds: selectedAddonsIds,
        packagesIds: selectedPackagesIds,
        offerings: selectedOfferings,
      });
      onSuccess();
    } catch (error) {
      onError();
    } finally {
      setIsLoading(false);
    }
  };

  return { onSubmit, isLoading };
};
