import isAfter from "date-fns/isAfter";
import isSameDay from "date-fns/isSameDay";
import subMinutes from "date-fns/subMinutes";
import { formatISO } from "date-fns";
import { DashboardAppointment } from "./getAppointments";
import {
  convertDbDateToCenterTZ,
  getNowUTC,
} from "../../../common/dateHelpers";

export type MinimumStartFilterMinutes = "startOfDay" | number;

const filterDashboardAppointments = (params: {
  appointments: DashboardAppointment[];
  minimumStartFilterMinutes?: MinimumStartFilterMinutes;
}): DashboardAppointment[] => {
  const { appointments, minimumStartFilterMinutes } = params;

  if (minimumStartFilterMinutes === "startOfDay") {
    const nowUTC = getNowUTC();

    return appointments.filter((appointment) => {
      const start = appointment.time_slot.started_at;
      const { timezone } = appointment.center;

      const nowCenterTimezone = convertDbDateToCenterTZ({
        dateString: formatISO(nowUTC),
        timezone,
      }).dateTimezone;

      const startCenterTimezone = convertDbDateToCenterTZ({
        dateString: start,
        timezone,
      }).dateTimezone;

      const isTodayDate = isSameDay(nowCenterTimezone, startCenterTimezone);

      return isTodayDate;
    });
  }

  const nowUTC = getNowUTC();

  return appointments.filter((appointment) => {
    if (!minimumStartFilterMinutes) return true;

    const start = appointment.time_slot.started_at;
    const { timezone } = appointment.center;

    const nowCenterTimezone = convertDbDateToCenterTZ({
      dateString: formatISO(nowUTC),
      timezone,
    }).dateTimezone;

    const startCenterTimezone = convertDbDateToCenterTZ({
      dateString: start,
      timezone,
    }).dateTimezone;

    const isTodayDate = isSameDay(nowCenterTimezone, startCenterTimezone);

    const isMinTime = isAfter(
      startCenterTimezone,
      subMinutes(nowCenterTimezone, minimumStartFilterMinutes),
    );

    return isTodayDate && isMinTime;
  });
};

export default filterDashboardAppointments;
