import { Option } from "./input";

type Props = {
  key: string;
  isFirst: boolean;
  side: string | number | boolean | null;
  isDual: boolean;
  isLeftDisabled?: boolean;
  isRightDisabled?: boolean;
};

const checkIsOfferingSideSelected = (
  selectedCompareOptions: (Option | null)[],
  side: string,
) => {
  return selectedCompareOptions.some(
    (selectedCompareOption) => selectedCompareOption?.metadata?.side === side,
  );
};

const getSelectCenterServiceFormItemProps = (params: {
  index: number;
  selectedOption: Option | null;
  selectedOptions: (Option | null)[];
}): Props => {
  const { index, selectedOption, selectedOptions } = params;

  const { isDual = false, side } = selectedOption?.metadata || {};

  const isFirst = index === 0;

  const key = selectedOption
    ? `${selectedOption.groupId}-${selectedOption.value}-${index}-${
        selectedOption.metadata?.side || "single"
      }`
    : `empty${index}`;

  if (!isDual) return { key, isFirst, isDual: !!isDual, side };

  const selectedCompareOptions = selectedOptions.filter(
    (selectedCompareOption) =>
      selectedCompareOption?.value === selectedOption?.value,
  );

  const isLeftDisabled = checkIsOfferingSideSelected(
    selectedCompareOptions,
    "left",
  );

  const isRightDisabled = checkIsOfferingSideSelected(
    selectedCompareOptions,
    "right",
  );

  return {
    key,
    isFirst,
    isDual: !!isDual,
    side,
    isLeftDisabled,
    isRightDisabled,
  };
};

export default getSelectCenterServiceFormItemProps;
