import React, { FC } from "react";
import { useIntl } from "react-intl";

import commonMessages from "../../../common/messages";
import Box from "../../Unknown/Box";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";

import useStyles from "./useStyles";
import { AddOnsState, CenterConfig } from "./types";
import { Option as CenterServiceOption } from "./input";

export interface AppointmentSummaryDetailsProps {
  centerConfig?: CenterConfig;
  selectedCenterServices?: (CenterServiceOption | null)[];
  selectedAddOns?: AddOnsState;
  discountSum: number;
  priceWithDiscount: number;
}

const AppointmentSummaryDetails: FC<AppointmentSummaryDetailsProps> = ({
  centerConfig,
  selectedCenterServices = [],
  selectedAddOns = [],
  discountSum,
  priceWithDiscount,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const classes = useStyles();

  const { defaultCurrencyCode } = centerConfig ?? {};

  const totalPriceWithDiscountText = formatNumber(priceWithDiscount, {
    style: "currency",
    currency: defaultCurrencyCode,
  });

  const isDiscountShown = discountSum !== 0;

  const sideTranslations: Record<string, string> = {
    left: formatMessage(commonMessages.left),
    right: formatMessage(commonMessages.right),
  };

  const centerServicesPositions = selectedCenterServices.map((service) => ({
    name: service?.label,
    side: service?.metadata?.side,
    price: service?.price,
  }));

  const addOnsPositions = selectedAddOns.map((addOn) => ({
    name: addOn.name,
    price: addOn.price
      ? formatNumber(addOn.price, {
          style: "currency",
          currency: defaultCurrencyCode,
        })
      : "",
    side: undefined,
  }));

  const summaryPositions = [...centerServicesPositions, ...addOnsPositions];

  const isSummaryPositionsShown = !!summaryPositions.length;

  return (
    <Box className={classes.summaryRoot} position="sticky" top={15}>
      <Grid container spacing={5} direction="column">
        {isSummaryPositionsShown && (
          <Grid item>
            {summaryPositions.map((summaryPosition, index) => {
              if (!summaryPosition) return null;

              const { name, side, price } = summaryPosition;

              const sideTranslation =
                typeof side === "string" ? sideTranslations[side] : "";

              const text = sideTranslation
                ? `${name} (${sideTranslation})`
                : name;

              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${text}-${index}`}
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Box mr={2}>
                    <Typography sx={{ wordBreak: "break-word" }}>
                      {text}
                    </Typography>
                  </Box>
                  <Typography>{price}</Typography>
                </Box>
              );
            })}
          </Grid>
        )}
        {isDiscountShown && (
          <Grid item>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography>{formatMessage(commonMessages.discount)}</Typography>
              <Typography>
                -
                {formatNumber(discountSum, {
                  style: "currency",
                  currency: defaultCurrencyCode,
                })}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid item>
          <Box display="flex" justifyContent="space-between">
            <Typography fontWeight={700}>
              {formatMessage(commonMessages.total)}
            </Typography>
            <Typography fontWeight={700}>
              {totalPriceWithDiscountText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentSummaryDetails;
