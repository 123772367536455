import React, { FC, useState } from "react";
import DataGrid, {
  GridColumnVisibilityModel,
  GridSortItem,
} from "../../Unknown/DataGrid";
import useDashboardColumns, {
  DashboardColumnsParams,
} from "./useDashboardColumns";
import { AppointmentRowItem } from "./types";
import useStyles from "./useStyles";
import AppointmentTableTopBar from "../AppointmentTableTopBar";
import { DateRangePickerProps } from "../../Unknown/DateRangePicker";
import { PAGE_SIZE_OPTIONS } from "../../../common/constants";
import CustomerLogInDialog, {
  CustomerLogInDialogProps,
} from "../../Customer/CustomerLogInDialog";
import { AppointmentMarkNoShowDialogProps } from "../AppointmentMarkNoShowDialog";
import AppointmentCheckInDialog from "../AppointmentCheckInDialog";

type AppointmentsTableGridProps = {
  columnsConfig: DashboardColumnsParams;
  rows: AppointmentRowItem[];
  isLoading?: boolean;
  dateRangePickerProps?: DateRangePickerProps<Date>;
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  tableKey?: string;
  loginDialogProps: CustomerLogInDialogProps | null;
  checkInDialogProps: AppointmentMarkNoShowDialogProps;
};

const isPastModel: GridSortItem = { field: "isPast", sort: "asc" };

const initialSortModel: GridSortItem[] = [
  isPastModel,
  { field: "date", sort: "asc" },
  { field: "time", sort: "asc" },
];

const AppointmentsTableGrid: FC<AppointmentsTableGridProps> = ({
  columnsConfig,
  rows,
  isLoading,
  dateRangePickerProps,
  initialColumnVisibilityModel,
  tableKey,
  loginDialogProps,
  checkInDialogProps,
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const [sortModel, setSortModel] = useState<GridSortItem[]>(initialSortModel);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    initialColumnVisibilityModel,
  );

  const { root, panel } = useStyles();

  const columns = useDashboardColumns(columnsConfig);

  const onSortModelChange = (newModel: GridSortItem[]) => {
    const isPastIncluded = newModel.find((model) => model.field === "isPast");
    const isPastModelProps = isPastIncluded ? [] : [isPastModel];

    setSortModel([...isPastModelProps, ...newModel]);
  };

  return (
    <>
      <DataGrid
        key={tableKey}
        autoHeight
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        disableRowSelectionOnClick
        loading={isLoading}
        className={root}
        pagination
        onSortModelChange={onSortModelChange}
        sortModel={sortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        getRowClassName={(params) => {
          const { isPast } = params.row;

          return isPast ? "row--past" : "row--general";
        }}
        slots={{ toolbar: AppointmentTableTopBar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            dateRangePickerProps,
          },
          panel: {
            className: panel,
          },
        }}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
      />
      {loginDialogProps && <CustomerLogInDialog {...loginDialogProps} />}
      {checkInDialogProps && (
        <AppointmentCheckInDialog {...checkInDialogProps} />
      )}
    </>
  );
};

export default AppointmentsTableGrid;
