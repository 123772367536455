import React, { FC, useMemo } from "react";
import Button from "../../Unknown/Button";
import Grid from "../../Unknown/Grid";
import Typography from "../../Unknown/Typography";
import { AddCircleOutlineIcon, AddIcon } from "../../Unknown/Icons";

import useCenterServiceOptions from "./useCenterServiceOptions";
import { CenterConfig } from "./types";
import useTranslations from "./translations";
import useStyles from "./useStyles";
import getSelectCenterServiceFormItemProps from "./getSelectCenterServiceItemProps";
import SelectCenterServiceSideInput from "./sideInput";
import SelectCenterServiceInput, { Groups } from "./input";

interface SelectCenterServiceFormProps {
  isLoading: boolean;
  isSubmitting: boolean;
  centerConfig: CenterConfig;
  optionsProps: ReturnType<typeof useCenterServiceOptions>;
}

const SelectCenterServiceForm: FC<SelectCenterServiceFormProps> = ({
  isLoading,
  isSubmitting,
  centerConfig,
  optionsProps,
}) => {
  const classes = useStyles();

  const { translations } = useTranslations();

  const {
    options,
    offerings,
    packages,
    selectedOptions,
    isCanCreateMore,
    isAllCenterServicesValid,
    addCenterService,
    removeCenterService,
    changeCenterService,
    changeSelectedSide,
  } = optionsProps;

  const groups: Groups = useMemo(() => {
    return {
      package: translations.packagesTitle,
      "single-offering": translations.singleOfferingsTitle,
    };
  }, [translations]);

  const { status: offeringsStatus } = offerings;
  const { status: packagesStatus } = packages;

  return (
    <Grid container>
      {selectedOptions.map((selectedOption, index) => {
        const { key, isFirst, isDual, side, isLeftDisabled, isRightDisabled } =
          getSelectCenterServiceFormItemProps({
            index,
            selectedOption,
            selectedOptions,
          });

        return (
          <Grid key={key} item container>
            {!isFirst && (
              <Grid
                item
                display="flex"
                justifyContent="center"
                width="100%"
                my={2}
              >
                <AddIcon fontSize="large" className={classes.addIconRoot} />
              </Grid>
            )}
            <Grid item xs={12}>
              <SelectCenterServiceInput
                inputLabel={translations.selectScan}
                options={options}
                groups={groups}
                isDiscountShown={index !== 0}
                selectedOption={selectedOption}
                onChange={changeCenterService(index)}
                isLoading={
                  offeringsStatus === "loading" || packagesStatus === "loading"
                }
                isDisabled={isSubmitting}
              />
            </Grid>
            {(!isFirst || isDual) && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                mt={2}
              >
                {isDual && (
                  <Grid item flex={1}>
                    <SelectCenterServiceSideInput
                      id={`${key}-select-side`}
                      value={side}
                      onChange={changeSelectedSide(index)}
                      isLeftDisabled={isLeftDisabled}
                      isRightDisabled={isRightDisabled}
                    />
                  </Grid>
                )}
                {!isFirst && (
                  <Grid item ml={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      size="medium"
                      onClick={() => removeCenterService(index)}
                      disabled={isLoading}
                    >
                      {translations.removeBtn}
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        );
      })}

      <Grid item flexGrow={1} mt={3}>
        <Button
          fullWidth
          disabled={
            !isAllCenterServicesValid || !isCanCreateMore || isSubmitting
          }
          onClick={addCenterService}
          sx={{ whiteSpace: "unset" }}
          startIcon={<AddCircleOutlineIcon />}
          classes={{
            root: classes.addInputButton,
            startIcon: classes.addButtonIcon,
          }}
        >
          <Typography
            textTransform="none"
            variant="body1"
            color="text.secondary"
          >
            {translations.addInputButton(centerConfig)}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default SelectCenterServiceForm;
