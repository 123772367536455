import React, { FC, ReactNode } from "react";

import FormControl from "../../Unknown/FormControl";
import InputLabel from "../../Unknown/InputLabel";
import Select, { SelectChangeEvent } from "../../Unknown/Select";
import MenuItem from "../../Unknown/MenuItem";
import useTranslations from "./translations";

type SelectCenterServiceSideInputProps = {
  id: string;
  value: string | number | boolean | null;
  onChange: (
    event: SelectChangeEvent<string | number | boolean | null>,
    child: ReactNode,
  ) => void;
  isLeftDisabled?: boolean;
  isRightDisabled?: boolean;
};

const SelectCenterServiceSideInput: FC<SelectCenterServiceSideInputProps> = ({
  id,
  value,
  onChange,
  isLeftDisabled,
  isRightDisabled,
}) => {
  const { translations } = useTranslations();

  return (
    <FormControl fullWidth required size="small">
      <InputLabel id={`${id}-label`}>{translations.selectSideLabel}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        label={translations.selectScan}
        onChange={onChange}
        size="small"
      >
        <MenuItem value="left" disabled={isLeftDisabled}>
          {translations.leftSide}
        </MenuItem>
        <MenuItem value="right" disabled={isRightDisabled}>
          {translations.rightSide}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectCenterServiceSideInput;
