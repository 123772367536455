import { AppointmentPreviewCardProps } from "../AppointmentPreviewCard";
import { Appointment } from "./types";
import useTranslations from "./useTranslations";

const useAppointmentPreviewCard = (params: {
  appointment: Appointment;
  refetch: () => void;
  onAddAdditionalViewClick: (id: string) => void;
  onEditStudyClick: ({ url }: { url: string }) => void;
  onReadStudyClick: ({ id, url }: { id: string; url: string }) => void;
}): AppointmentPreviewCardProps => {
  const { title } = useTranslations();

  return {
    title,
    ...params,
  };
};

export default useAppointmentPreviewCard;
