import { useMemo, useCallback, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { SelectedOfferingSide } from "@Shape-Digital/kudzu-data/lib/types/common";

import { SelectChangeEvent } from "../../Unknown/Select";

import { Option as SelectCenterServiceInputOption } from "./input";
import {
  CenterConfig,
  CenterServiceGroupId,
  CenterServicesState,
  SelectedCenterService,
  AddOnsState,
} from "./types";
import useCenterServices from "./useCenterServices";
import getTotalSummary from "./getTotalSummary";
import useAddOns from "./useAddOns";

interface InitialServiceProps {
  id: string;
  side?: SelectedOfferingSide;
}

interface UseCenterServiceOptionsProps {
  centerConfig?: CenterConfig;
  initialServices: InitialServiceProps[];
  initialAddOnsIds: string[];
}

const useCenterServiceOptions = ({
  centerConfig,
  initialServices,
  initialAddOnsIds,
}: UseCenterServiceOptionsProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedCenterServices, setSelectedCenterServices] =
    useState<CenterServicesState>([]);
  const [selectedAddOns, setSelectedAddOns] = useState<AddOnsState>([]);

  const { formatNumber } = useIntl();
  const {
    dataState: centerServices,
    offerings,
    packages,
  } = useCenterServices({ centerConfig });

  const { data: addOns, status: addOnsStatus } = useAddOns(centerConfig?.id);

  const centerServiceList = useMemo(() => {
    const { data, status } = centerServices;

    if (status !== "success") return [];

    return Object.entries(data).map(([id, centerService]) => ({
      value: id,
      ...centerService,
    }));
  }, [centerServices]);

  const options = useMemo<SelectCenterServiceInputOption[]>(() => {
    const result = centerServiceList.reduce<SelectCenterServiceInputOption[]>(
      (acc, centerService) => {
        const { groupId, id, name, price, description, discountPrice } =
          centerService;

        const isDual =
          centerService.groupId === "single-offering" &&
          centerService.scanSide === "dual";

        const alreadySelectedItems = selectedCenterServices.filter(
          (selectedCenterService) =>
            selectedCenterService?.value &&
            centerService?.id === selectedCenterService.value,
        );

        const isDisabled = isDual
          ? alreadySelectedItems.length === 2
          : alreadySelectedItems.length === 1;

        const item = {
          groupId,
          value: id,
          label: name,
          price: formatNumber(price, {
            style: "currency",
            currency: centerConfig?.defaultCurrencyCode,
          }),
          description,
          discountPrice: formatNumber(discountPrice, {
            style: "currency",
            currency: centerConfig?.defaultCurrencyCode,
          }),
          isDisabled,
        };

        return [...acc, item];
      },
      [],
    );
    return result;
  }, [
    centerServiceList,
    selectedCenterServices,
    formatNumber,
    centerConfig?.defaultCurrencyCode,
  ]);

  const initialSelectedCenterServices = useMemo(() => {
    return initialServices.map((initialServiceProps) => {
      const initialServiceOption = centerServiceList.find(
        (centerServiceItem) => centerServiceItem.id === initialServiceProps.id,
      );

      if (!initialServiceOption) return null;

      return {
        ...initialServiceOption,
        scanSide: "dual",
        side: initialServiceProps.side,
      };
    });
  }, [initialServices, centerServiceList]);

  const initialSelectedAddOns = useMemo(() => {
    return addOns?.filter((addOn) => initialAddOnsIds.includes(addOn.id)) || [];
  }, [addOns, initialAddOnsIds]);

  const initialize = useCallback(() => {
    setSelectedCenterServices(initialSelectedCenterServices);
    setSelectedAddOns(initialSelectedAddOns || []);
    setIsInitialized(true);
  }, [initialSelectedCenterServices, initialSelectedAddOns]);

  useEffect(() => {
    if (
      !isInitialized &&
      centerServiceList.length &&
      addOnsStatus === "success"
    ) {
      initialize();
    }
  }, [
    centerServiceList,
    initialServices,
    isInitialized,
    addOns,
    initialAddOnsIds,
    initialSelectedCenterServices,
    initialSelectedAddOns,
    initialize,
    addOnsStatus,
  ]);

  const selectedOptions = useMemo(() => {
    return selectedCenterServices.map((selectedCenterService) => {
      const { data, status } = centerServices;

      if (!selectedCenterService || status !== "success") return null;

      const centerService = data[selectedCenterService.value];

      if (!centerService) return null;

      const { groupId, id, name, price, description, discountPrice } =
        centerService;

      const isDual =
        centerService.groupId === "single-offering" &&
        centerService.scanSide === "dual";

      const result: SelectCenterServiceInputOption = {
        groupId,
        value: id,
        label: name,
        price: formatNumber(price, {
          style: "currency",
          currency: centerConfig?.defaultCurrencyCode,
        }),
        description,
        discountPrice: formatNumber(discountPrice, {
          style: "currency",
          currency: centerConfig?.defaultCurrencyCode,
        }),
        isDisabled: true,
        metadata: {
          isDual,
          side: selectedCenterService.side || "",
        },
      };

      return result;
    });
  }, [
    centerConfig?.defaultCurrencyCode,
    centerServices,
    formatNumber,
    selectedCenterServices,
  ]);

  const isAllCenterServicesValid = useMemo(() => {
    const { data, status } = centerServices;

    if (status !== "success") return false;

    return selectedCenterServices.every((selectedCenterService) => {
      if (!selectedCenterService) return false;

      const centerService = data[selectedCenterService.value];

      if (!centerService) return false;

      const isCenterServiceWithDualSide =
        centerService.groupId === "single-offering" &&
        centerService.scanSide === "dual";

      if (isCenterServiceWithDualSide && !selectedCenterService.side) {
        return false;
      }

      return true;
    });
  }, [centerServices, selectedCenterServices]);

  const isDirty = useMemo(() => {
    if (initialSelectedCenterServices.length !== selectedCenterServices.length)
      return true;

    if (initialAddOnsIds.length !== selectedAddOns.length) return true;

    if (selectedAddOns.some((addOn) => !initialAddOnsIds.includes(addOn.id))) {
      return true;
    }

    const isCenterServicesDirty = selectedOptions.some((option) => {
      const initialOption = initialSelectedCenterServices.find(
        (initialOptionItem) => initialOptionItem?.value === option?.value,
      );

      if (!initialOption) return true;

      const isOptionDirty =
        (initialOption.side || "") !== (option?.metadata?.side || "");

      return isOptionDirty;
    });

    return isCenterServicesDirty;
  }, [
    initialAddOnsIds,
    initialSelectedCenterServices,
    selectedAddOns,
    selectedCenterServices.length,
    selectedOptions,
  ]);

  const isCanCreateMore = useMemo(() => {
    const { status, data } = centerServices;

    if (status !== "success") return false;

    const selectableCount = Object.keys(data).reduce(
      (acc, centerServiceKey) => {
        const centerService = data[centerServiceKey];

        if (!centerService) return acc;
        const isDual =
          centerService.groupId === "single-offering" &&
          centerService.scanSide === "dual";

        return isDual ? acc + 2 : acc + 1;
      },
      0,
    );

    return selectedOptions.length !== selectableCount;
  }, [centerServices, selectedOptions]);

  const addCenterService = useCallback(() => {
    setSelectedCenterServices((prev) => [...prev, null]);
  }, [setSelectedCenterServices]);

  const removeCenterService = useCallback(
    (index: number) => {
      setSelectedCenterServices((prev) => {
        const list = prev.filter((_, idx) => idx !== index);
        return list.length ? list : [null];
      });
    },
    [setSelectedCenterServices],
  );

  const changeCenterService = useCallback(
    (index: number) => {
      return (option: SelectCenterServiceInputOption) => {
        const { status, data } = centerServices;

        if (status !== "success") {
          return;
        }

        const { value, groupId } = option;

        const centerService = data[value];

        if (!centerService) {
          setSelectedCenterServices((prev) =>
            prev.map((item, idx) => (idx === index ? null : item)),
          );
          return;
        }

        const { name, price, discountPrice, durationMinutes } = centerService;

        if (!option) {
          setSelectedCenterServices((prev) =>
            prev.map((item, idx) => (idx === index ? null : item)),
          );
        }

        const selectedCenterService: SelectedCenterService = {
          value,
          groupId: groupId as CenterServiceGroupId,
          name,
          price,
          discountPrice,
          durationMinutes,
        };

        setSelectedCenterServices((prev) =>
          prev.map((item, idx) =>
            idx === index ? selectedCenterService : item,
          ),
        );
      };
    },
    [centerServices, setSelectedCenterServices],
  );

  const changeSelectedSide = useCallback(
    (index: number) => {
      return (event: SelectChangeEvent<string | number | boolean | null>) => {
        setSelectedCenterServices((prev) =>
          prev.map((item, idx) => {
            if (idx !== index || !item) return item;

            return {
              ...item,
              side: event.target.value as SelectedOfferingSide,
            };
          }),
        );
      };
    },
    [setSelectedCenterServices],
  );

  const summary = getTotalSummary({
    centerServices: selectedCenterServices.filter(
      (centerService): centerService is SelectedCenterService =>
        centerService !== null,
    ),
    discountAmount: centerConfig?.discountAmount,
    addOns: selectedAddOns,
  });

  return {
    summary,
    options,
    packages,
    offerings,
    addOns,
    isDirty,
    isInitialized,
    selectedOptions,
    isCanCreateMore,
    isAllCenterServicesValid,
    selectedAddOns,
    initialize,
    setSelectedAddOns,
    addCenterService,
    removeCenterService,
    changeCenterService,
    changeSelectedSide,
  };
};

export default useCenterServiceOptions;
