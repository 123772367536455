import Big from "big.js";
import getTimezoneOffset from "date-fns-tz/getTimezoneOffset";
import addMilliseconds from "date-fns/addMilliseconds";
import addMinutes from "date-fns/addMinutes";
import formatISO from "date-fns/formatISO";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import hoursToMinutes from "date-fns/hoursToMinutes";
import isBefore from "date-fns/isBefore";
import set from "date-fns/set";
import subMinutes from "date-fns/subMinutes";
import millisecondsToMinutes from "date-fns/millisecondsToMinutes";
import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const addTimeToDate = (date: Date, time: Date) => {
  return set(date, {
    hours: getHours(time),
    minutes: getMinutes(time),
    seconds: 0,
    milliseconds: 0,
  });
};

export const getTimezoneOffsetMillis = (timezone?: string | null) => {
  if (!timezone) return 0;

  const offset = getTimezoneOffset(timezone);

  if (!offset || Number.isNaN(offset)) return 0;

  return offset;
};

export const formatTimeDirectlyToUTC_ISO = (date: Date) => {
  const formattedDate = formatISO(date);
  const ISOStringWithoutTimeZone = formattedDate.replace(/\+\d\d:\d\d/, "");
  return ISOStringWithoutTimeZone;
};

export const formatClientTimezoneToUTC_ISO = (
  date: Date,
  timezone?: string | null,
) => {
  const timezoneOffsetMinutes = timezone
    ? millisecondsToMinutes(getTimezoneOffsetMillis(timezone))
    : new Date().getTimezoneOffset();

  const dateUTC = subMinutes(date, timezoneOffsetMinutes);
  return formatTimeDirectlyToUTC_ISO(dateUTC);
};

export const getNowUTC = () => {
  return addMinutes(new Date(), new Date().getTimezoneOffset());
};

export const UTCDateTimeToTimezone = (
  date: Date,
  timezoneOffsetMillis: number,
) => {
  return addMilliseconds(date, timezoneOffsetMillis);
};

export const isTimeZonePast = (params: {
  date: Date;
  timezone?: string | null;
}) => {
  const { date, timezone } = params;

  const timezoneOffsetMillis = getTimezoneOffsetMillis(timezone);

  const nowTimezone = UTCDateTimeToTimezone(getNowUTC(), timezoneOffsetMillis);

  const dateTimezone = UTCDateTimeToTimezone(date, timezoneOffsetMillis);

  const isPast = isBefore(dateTimezone, nowTimezone);

  return isPast;
};

export const roundDateMinutes = (date: Date, roundTo: number) => {
  const ms = 1000 * 60 * roundTo;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const getDayMinutes = (date: Date) => {
  const hours = getHours(date);

  const minutes = getMinutes(date);

  const dayMinutes = Big(hoursToMinutes(hours)).plus(minutes).toNumber();

  return dayMinutes;
};

export const formatLocaleTimeString = (date: Date, locales?: string[]) => {
  return date
    .toLocaleTimeString(locales, {
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(" AM", "am")
    .replace(" PM", "pm");
};

export const getDateTimeValues = (
  date: Date,
): { dateValue: string; timeValue: string } => {
  return {
    dateValue: date.toLocaleDateString(),
    timeValue: formatLocaleTimeString(date),
  };
};

export const formatISOInUTC = (date: Date) => {
  const formattedDate = formatISO(date);
  const ISOStringWithoutTimeZone = formattedDate.replace(
    /[\\+|\\-]\d\d:\d\d$/,
    "",
  );
  return ISOStringWithoutTimeZone;
};

export const getMonthDayList = (date?: Date | null): number[] => {
  const today = new Date();

  const start = startOfMonth(date || today);
  const end = endOfMonth(date || today);
  const daysArray = eachDayOfInterval({ start, end });

  return daysArray.map((day) => Number(format(day, "d")));
};

export const createUTCDate = (dateString: string): Date => {
  const [year, month, day, hour, minute, second] = dateString
    .split(/[-T:]/)
    .map(Number);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
};

export const convertDbDateToCenterTZ = ({
  dateString,
  timezone,
}: {
  dateString: string;
  timezone: string;
}) => {
  const dateUTC = createUTCDate(dateString);

  return {
    dateUTC,
    dateTimezone: utcToZonedTime(dateUTC, timezone),
  };
};

export const convertZonedDateToUtcDateString = ({
  date,
  timezone,
}: {
  date: Date;
  timezone: string;
}) => {
  const dateUtc = zonedTimeToUtc(date, timezone);

  const utcDateString = formatInTimeZone(
    dateUtc,
    "UTC",
    "yyyy-MM-dd'T'HH:mm:ssXXX",
  );

  return utcDateString;
};
