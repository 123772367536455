import { useMemo, useState } from "react";
import { getTimezoneOffsetMillis } from "../../../common/dateHelpers";
import calcAppointmentDurationMinutes from "../../../helpers/appointment/calcAppointmentDurationMinutes";
import { useAppContext } from "../../Unknown/AppContext";
import { AppointmentRescheduleDialogContainerProps } from "../AppointmentRescheduleDialogContainer";
import calcMinBeforeBookingSeconds from "./calcMinBeforeBookingSeconds";
import getDateLimits from "./getDateLimits";
import { Appointment } from "./types";

const useAppointmentRescheduleDialogContainer = (params: {
  appointment: Appointment | null;
  refetch: () => Promise<void>;
}): {
  handleOpen: () => void;
  props: AppointmentRescheduleDialogContainerProps;
} => {
  const { appointment, refetch } = params;

  const { systemConfig } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const center = useMemo(() => {
    if (!appointment) return null;

    const timezoneOffsetMillis = getTimezoneOffsetMillis(
      appointment.center.timezone,
    );

    const centerConfig = appointment.center.center_configs?.[0];

    const minTimeBeforeBookingSeconds = calcMinBeforeBookingSeconds({
      systemConfig,
      centerConfig,
    });

    const dateLimits = getDateLimits({
      timezoneOffsetMillis,
      minTimeBeforeBookingSeconds,
    });

    const operatingHours = appointment.center.center_operating_hours.reduce(
      (acc, day) => {
        const { day_of_week_number, from_minutes_at, to_minutes_at } = day;

        return {
          ...acc,
          [day_of_week_number]: {
            fromMinutes: from_minutes_at,
            toMinutes: to_minutes_at,
          },
        };
      },
      {},
    );

    return {
      id: appointment.center.id,
      dateLimits,
      operatingHours,
      timezone: appointment.center.timezone,
      timezoneOffsetMillis,
    };
  }, [appointment, systemConfig]);

  const appointmentProp = useMemo(() => {
    if (!appointment) return null;

    const defaultDuration =
      appointment.center.center_configs[0]?.default_offering_duration_minutes ??
      systemConfig.default_offering_duration_minutes;

    return {
      id: appointment.id,
      durationMinutes: calcAppointmentDurationMinutes({
        offerings: appointment.appointment_offerings,
        packages: appointment.appointment_packages,
        defaultDuration,
      }),
    };
  }, [appointment, systemConfig.default_offering_duration_minutes]);

  return {
    handleOpen,
    props: {
      isOpen,
      handleClose,
      center,
      appointment: appointmentProp,
      handleChangeAppointmentStartedAt: refetch,
    },
  };
};

export default useAppointmentRescheduleDialogContainer;
